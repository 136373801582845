<template>
  <h1>{{ title }}</h1>
  <div class="most-wanted">
    <div class="fade-in" v-for="i in [currentIndex]" :key="i">
      <img @click="next" :src="currentImg" alt="" />
    </div>

  </div>
</template>
<script>
import EventService from "../services/EventService";
export default {
  components: {},
  name: "Slider",
  data() {
    return {
      images: "",
      timer: null,
      currentIndex: 0,
      title: "Most Wanted"
    };
  },
  created() {
    // fetch wanted img data
    this.getUserData();
  },

  methods: {
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    getUserData() {
      EventService.getWanted()
        .then(response => {
          this.images = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },

  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
};
</script>
<style lang="scss" scoped>
h1 {
  background: #3e333b;
  color: #ffffff;
}
.most-wanted {
  background: url("../assets/brickwall.jpg") no-repeat;
  background-size: cover;
  padding: 5.45% 0;
  min-height: 100%;
  div.animated-enter-active:nth-of-type(2).animated-enter-to:nth-of-type(2) {
    display: none;
  }
  .fade-in {
    animation: fadeIn ease 2s;
    transition: ease all 2s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    max-width: 23em;
    width: 100%;
    transition: ease all 1s;
    cursor: pointer;
    &:hover,
    &:active {
      transform: scale(1.1);
    }
  }

  .prev,
  .next {
    cursor: pointer;
    position: relative;
    top: -2em;
    width: auto;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    transition: 1s ease;
    text-decoration: none;
    user-select: none;
  }

  .next {
    right: 0;
  }

  .prev {
    left: 0;
  }

  .prev:hover,
  .next:hover {
    color: green;
  }
}
</style>
