<template>
  <h2>Game: {{ id }}</h2>
  <div id="full-report">
    <div class="report-box transition-down" v-if="game">
      <img :src="game.imgSrc" />
      <p class="transition-up" v-for="par in game.description" :key="par.id">{{ par }}</p>
    </div>
    <div v-else><h2>Loading Game Report</h2></div>
  </div>
</template>

<script>
import EventService from "../services/EventService";

export default {
  name: "GameDetails",
  props: ["id"],
  data() {
    return {
      game: null
    };
  },
  created() {
    // fetch game by id and set local data
    EventService.getGame(this.id)
      .then(response => {
        this.game = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang="scss" scoped>
h2 {
  background: #0f60c9;
  color: #ffffff;
  text-align: center;
  font-size: 2em;
  padding: 0.5em 0;
  margin: 0;
}
#full-report {
  background: url(../assets/report-bg.png) no-repeat;
  background-size: cover;
  padding: 2em 0;


  .report-box {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
    background: #fff;
    max-width: 1000px;
    margin: 1em auto 1.85%;
    padding-bottom: 2em;

    img {
      margin-bottom: 2em;
      max-width: 100%;
      animation: transition-down 2s;
    }
    p {
      text-align: left;
      padding: 0 2em;
      max-width: 50em;
      margin: auto;
      line-height: 2.1em;
      animation: transition-up 2s;
    }
  }
}
@keyframes transition-down {
  from {
    opacity: 0;
    transform: translateY(-105%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes transition-up {
  from {
    opacity: 0;
    transform: translateY(105%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
